// src/components/admin/Admin.tsx

import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container, useMediaQuery, Theme } from '@mui/material';
import Dashboard from './Dashboard';
import RideManagement from './RideManagement';
import UserManagement from './UserManagement';
import RideServiceManagement from './RideServiceManagement';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Admin: React.FC = () => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ overflowX: 'hidden' }}>
      <Box sx={{ width: '100%', mt: 4 }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="admin tabs"
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
          }}
        >
          <Tab label="Dashboard" />
          <Tab label="Ride Management" />
          <Tab label="User Management" />
          <Tab label="Ride Service Management" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          <TabPanel value={value} index={0}>
            <Dashboard />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RideManagement />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserManagement />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RideServiceManagement />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

export default Admin;