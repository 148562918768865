import React from 'react';
import AutoComplete from '../common/AutoComplete';
import { useGoogleMaps } from '../maps/GoogleMapsLoader';
import { TextField, InputAdornment } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface LocationInputProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  'aria-describedby': string;
  label: string;
}

const LocationInput: React.FC<LocationInputProps> = ({ id, value, onChange, placeholder, 'aria-describedby': ariaDescribedby, label }) => {
  const { isLoaded } = useGoogleMaps();

  const renderInput = (params: any) => (
    <TextField
      {...params}
      fullWidth
      label={label}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <LocationOnIcon color="primary" />
          </InputAdornment>
        ),
        style: { fontSize: '1.2rem', padding: '12px' }
      }}
      InputLabelProps={{
        style: { fontSize: '1.2rem' }
      }}
    />
  );

  const handleChange = (event: React.ChangeEvent<{}> | React.ChangeEvent<HTMLInputElement>, newValue: string | null) => {
    onChange(newValue || '');
  };

  if (!isLoaded) {
    return renderInput({});
  }

  return (
    <AutoComplete
      id={id}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      aria-describedby={ariaDescribedby}
      renderInput={renderInput}
    />
  );
};

export default LocationInput;