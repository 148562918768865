import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { RideService, PricingComponent } from '../../types/RideshareService';

const RideServiceManagement: React.FC = () => {
  const [services, setServices] = useState<RideService[]>([]);
  const [editingService, setEditingService] = useState<RideService | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      console.log('Fetching services...');
      const servicesSnapshot = await getDocs(collection(db, 'rideServices'));
      const servicesList = servicesSnapshot.docs.map(doc => {
        const data = doc.data() as RideService;
        console.log("Service ID:", doc.id);
        return {
          ...data,
          id: doc.id
        };
      });
      console.log('Fetched services:', servicesList);
      setServices(servicesList);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleOpenDialog = (service: RideService | null) => {
    setEditingService(service || {
      name: '',
      description: '',
      minimumPrice: 0, // Changed from basePrice to minimumPrice
      pricingComponents: []
    } as RideService);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingService(null);
  };

  const handleUpdateService = async (service: Partial<RideService>) => {
    try {
      console.log("Saving service data:", JSON.stringify(service, null, 2));
      let serviceRef;
      if (service.id) {
        // Updating an existing service
        serviceRef = doc(db, 'rideServices', service.id);
        await updateDoc(serviceRef, {
          name: service.name,
          description: service.description,
          minimumPrice: parseFloat(service.minimumPrice?.toString() || '0'),
          pricingComponents: service.pricingComponents?.map(component => ({
            name: component.name,
            isDiscount: component.isDiscount,
            type: component.type,
            value: parseFloat(component.value.toString()) || 0,
            order: component.order,
            applyToRoundTripOnly: component.applyToRoundTripOnly
          }))
        });
        console.log('Service updated successfully');
      } else {
        // Creating a new service
        const newService = {
          name: service.name,
          description: service.description,
          minimumPrice: parseFloat(service.minimumPrice?.toString() || '0'),
          pricingComponents: service.pricingComponents?.map(component => ({
            name: component.name,
            isDiscount: component.isDiscount,
            type: component.type,
            value: parseFloat(component.value.toString()) || 0,
            order: component.order,
            applyToRoundTripOnly: component.applyToRoundTripOnly
          }))
        };
        serviceRef = await addDoc(collection(db, 'rideServices'), newService);
        console.log('New service created successfully');
      }
      setIsDialogOpen(false);
      fetchServices(); // Refresh the list of services
    } catch (error) {
      console.error('Error updating/creating service:', error);
    }
  };

  const handleDeleteService = async (serviceId: string | undefined) => {
    console.log("handleDeleteService called with ID:", serviceId);
    console.log("Attempting to delete service with ID:", serviceId);
    if (!serviceId) {
      console.error("Invalid service ID");
      return;
    }
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        console.log("Deleting document from Firestore...");
        await deleteDoc(doc(db, 'rideServices', serviceId));
        console.log("Document deleted successfully");
        setServices(prevServices => {
          console.log("Updating local state...");
          return prevServices.filter(service => service.id !== serviceId);
        });
      } catch (error) {
        console.error("Error deleting service:", error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (editingService) {
      setEditingService({
        ...editingService,
        [e.target.name]: e.target.value
      });
    }
  };

  const handlePricingComponentChange = (index: number, field: keyof PricingComponent, value: string | number | boolean) => {
    if (editingService) {
      const updatedComponents = [...editingService.pricingComponents];
      updatedComponents[index] = {
        ...updatedComponents[index],
        [field]: value
      };
      setEditingService({
        ...editingService,
        pricingComponents: updatedComponents
      });
    }
  };

  const addPricingComponent = () => {
    if (editingService) {
      setEditingService({
        ...editingService,
        pricingComponents: [
          ...editingService.pricingComponents,
          {
            name: '',
            type: 'flat',
            value: 0,
            isDiscount: false,
            applyToRoundTripOnly: false,
            order: editingService.pricingComponents.length
          }
        ]
      });
    }
  };

  const removePricingComponent = (index: number) => {
    if (editingService) {
      const updatedComponents = editingService.pricingComponents.filter((_, i) => i !== index);
      setEditingService({
        ...editingService,
        pricingComponents: updatedComponents
      });
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Ride Service Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog(null)}
        style={{ marginBottom: '1rem' }}
      >
        Add New Service
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Pricing Components</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.description}</TableCell>
                <TableCell>
                  {service.pricingComponents.map(pc => (
                    `${pc.name}: ${pc.value}${pc.type === 'percentage' ? '%' : ''} 
                    ${pc.type === 'perMile' ? 'per mile' : pc.type === 'perMinute' ? 'per minute' : ''} 
                    ${pc.isDiscount ? '(Discount)' : ''}
                    ${pc.applyToRoundTripOnly ? '(Round Trip Only)' : ''}`
                  )).join(', ')}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleOpenDialog(service)}>Edit</Button>
                  <Button onClick={() => handleDeleteService(service.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{editingService?.id ? 'Edit Service' : 'Add New Service'}</DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              name="name"
              label="Name"
              value={editingService?.name || ''}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              name="description"
              label="Description"
              value={editingService?.description || ''}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              label="Minimum Price"
              type="number"
              value={editingService?.minimumPrice || 0}
              onChange={(e) =>
                setEditingService({
                  ...editingService!,
                  minimumPrice: parseFloat(e.target.value) || 0,
                })
              }
            />
            <Typography variant="h6" style={{ marginTop: '1rem' }}>
              Pricing Components
            </Typography>
            {editingService?.pricingComponents.map((component, index) => (
              <Box key={index} display="flex" flexWrap="wrap" alignItems="center" gap={2} sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 1 }}>
                <TextField
                  label="Name"
                  value={component.name}
                  onChange={(e) =>
                    handlePricingComponentChange(index, "name", e.target.value)
                  }
                  style={{ minWidth: "150px" }}
                />
                <TextField
                  label="Value"
                  type="number"
                  value={component.value}
                  onChange={(e) =>
                    handlePricingComponentChange(
                      index,
                      "value",
                      parseFloat(e.target.value) || 0,
                    )
                  }
                  style={{ width: "100px" }}
                />
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={component.type}
                    onChange={(e) =>
                      handlePricingComponentChange(
                        index,
                        "type",
                        e.target.value as "flat" | "perMile" | "perMinute" | "percentage",
                      )
                    }
                  >
                    <MenuItem value="flat">Flat</MenuItem>
                    <MenuItem value="perMile">Per Mile</MenuItem>
                    <MenuItem value="perMinute">Per Minute</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Order"
                  type="number"
                  value={component.order}
                  onChange={(e) =>
                    handlePricingComponentChange(
                      index,
                      "order",
                      parseInt(e.target.value) || 0,
                    )
                  }
                  style={{ width: "80px" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={component.isDiscount}
                      onChange={(e) =>
                        handlePricingComponentChange(
                          index,
                          "isDiscount",
                          e.target.checked,
                        )
                      }
                    />
                  }
                  label="Is Discount"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={component.applyToRoundTripOnly}
                      onChange={(e) =>
                        handlePricingComponentChange(
                          index,
                          "applyToRoundTripOnly",
                          e.target.checked,
                        )
                      }
                    />
                  }
                  label="Round Trip Only"
                />
                <IconButton onClick={() => removePricingComponent(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Button onClick={addPricingComponent}>Add Pricing Component</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => editingService && handleUpdateService(editingService)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RideServiceManagement;
