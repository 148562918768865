// src/components/admin/RideManagement.tsx

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, Tooltip, CircularProgress, Chip, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, query, where, orderBy, limit, startAfter, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SearchBar from './SearchBar';
import { exportToCSV } from '../../utils/csvExport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format, parseISO } from 'date-fns';

interface Ride {
  id: string;
  userId: string;
  pickup: string;
  dropoff: string;
  date: string;
  time: string;
  quote: number;
  originalQuote: number | null;
  status: 'pending' | 'approved' | 'completed' | 'cancelled';
  isRoundTrip: boolean;
  additionalAssistance: string;
  serviceName: string;
  distance: number;
  duration: number;
  lastUpdated: string;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const RIDES_PER_PAGE = 20;

const RideManagement: React.FC = () => {
  const [rides, setRides] = useState<Ride[]>([]);
  const [users, setUsers] = useState<{ [key: string]: User }>({});
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const initialFetchDone = useRef(false);

  const fetchUserInfo = useCallback(async (userId: string) => {
    if (users[userId]) return; // If we already have the user info, don't fetch again
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data() as User;
        setUsers(prevUsers => ({ ...prevUsers, [userId]: userData }));
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }, [users]);

  const fetchRides = useCallback(async (search: string = '', loadMore: boolean = false) => {
    console.log('fetchRides called:', { search, loadMore, currentLoading: loading });
    setLoading(true);
    setError(null);

    try {
      let rideQuery = query(
        collection(db, 'rides'),
        orderBy('date', 'desc'),
        limit(RIDES_PER_PAGE)
      );

      if (loadMore && lastVisible) {
        rideQuery = query(rideQuery, startAfter(lastVisible));
      }

      if (search) {
        rideQuery = query(rideQuery, where('userId', '==', search));
      }

      const rideSnapshot = await getDocs(rideQuery);
      const newRides = rideSnapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Ride data:', data);
        return { id: doc.id, ...data } as Ride;
      });

      // Fetch user info for each ride
      await Promise.all(newRides.map(ride => fetchUserInfo(ride.userId)));

      console.log('Fetched rides:', newRides);

      setRides(prevRides => {
        const updatedRides = loadMore ? [...prevRides, ...newRides] : newRides;
        console.log('Updating rides:', updatedRides);
        return updatedRides;
      });
      setLastVisible(rideSnapshot.docs[rideSnapshot.docs.length - 1]);
      setHasMore(rideSnapshot.docs.length === RIDES_PER_PAGE);
    } catch (error) {
      console.error("Error fetching rides:", error);
      setError("Failed to fetch rides. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [lastVisible, loading, fetchUserInfo]);

  useEffect(() => {
    console.log('useEffect triggered, searchTerm:', searchTerm);
    if (!initialFetchDone.current) {
      fetchRides(searchTerm);
      initialFetchDone.current = true;
    }
  }, [searchTerm, fetchRides]);

  const handleSearch = (term: string) => {
    console.log('Search term:', term);
    setSearchTerm(term);
    setRides([]);
    setLastVisible(null);
    setHasMore(true);
    initialFetchDone.current = false;  // Reset this so we can fetch again
  };

  const handleDeleteRide = async (rideId: string) => {
    if (window.confirm('Are you sure you want to delete this ride?')) {
      try {
        await deleteDoc(doc(db, 'rides', rideId));
        setRides(rides.filter(ride => ride.id !== rideId));
      } catch (error) {
        console.error('Error deleting ride:', error);
        setError("Failed to delete ride. Please try again.");
      }
    }
  };

  const handleUpdateStatus = async (rideId: string, newStatus: Ride['status']) => {
    try {
      await updateDoc(doc(db, 'rides', rideId), { status: newStatus });
      setRides(rides.map(ride => ride.id === rideId ? { ...ride, status: newStatus } : ride));
    } catch (error) {
      console.error('Error updating ride status:', error);
      setError("Failed to update ride status. Please try again.");
    }
  };

  const formatPrice = (price: number | null | undefined) => {
    if (price === null || price === undefined) return 'N/A';
    return `$${price.toFixed(2)}`;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return 'N/A';
    try {
      return format(parseISO(dateString), 'MM/dd/yyyy');
    } catch (error) {
      console.error(`Error formatting date: ${dateString}`, error);
      return 'Invalid Date';
    }
  };

  const formatTime = (timeString: string) => {
    if (!timeString) return 'N/A';
    try {
      return format(parseISO(`2000-01-01T${timeString}`), 'hh:mm a');
    } catch (error) {
      console.error(`Error formatting time: ${timeString}`, error);
      return 'Invalid Time';
    }
  };

  const handleExportCSV = () => {
    const dataToExport = rides.map(({ id, ...ride }) => ({
      ...ride,
      date: formatDate(ride.date),
      time: formatTime(ride.time),
      price: formatPrice(ride.quote),
      originalPrice: formatPrice(ride.originalQuote),
    }));
    exportToCSV(dataToExport, 'ride_management_data.csv');
  };

  const getStatusColor = (status: Ride['status']) => {
    switch (status) {
      case 'pending': return 'warning';
      case 'approved': return 'info';
      case 'completed': return 'success';
      case 'cancelled': return 'error';
      default: return 'default';
    }
  };

  const statusButtonStyle = (color: 'warning' | 'info' | 'success') => ({
    minWidth: '90px',
    py: 0.5,
    px: 1,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '4px',
    bgcolor: `${color}.main`,
    color: `${color}.contrastText`,
    '&:hover': { 
      bgcolor: `${color}.dark`,
    },
    '&:disabled': {
      bgcolor: `${color}.light`,
      color: `${color}.contrastText`,
    }
  });

  return (
    <Box sx={{ mt: 3, width: '100%', overflowX: 'auto' }}>
      <Typography variant="h6" sx={{ p: 2 }}>Ride Management</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <SearchBar onSearch={handleSearch} />
        <Tooltip title="Export to CSV">
          <IconButton color="primary" onClick={handleExportCSV} aria-label="export to csv">
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && rides.length === 0 && <Typography>No rides found.</Typography>}
      {!loading && rides.length > 0 && (
        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
          <Table stickyHeader sx={{ minWidth: 1500 }}>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Pickup</TableCell>
                <TableCell>Dropoff</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Original Price</TableCell>
                <TableCell>Round Trip</TableCell>
                <TableCell>Additional Assistance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell sx={{ width: '180px' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rides.map((ride) => {
                const user = users[ride.userId] || {};
                return (
                  <TableRow key={ride.id}>
                    <TableCell>{ride.userId}</TableCell>
                    <TableCell>{user.email || 'N/A'}</TableCell>
                    <TableCell>{`${user.firstName || ''} ${user.lastName || ''}`.trim() || 'N/A'}</TableCell>
                    <TableCell>{user.phoneNumber || 'N/A'}</TableCell>
                    <TableCell>{ride.pickup || 'N/A'}</TableCell>
                    <TableCell>{ride.dropoff || 'N/A'}</TableCell>
                    <TableCell>{formatDate(ride.date)}</TableCell>
                    <TableCell>{formatTime(ride.time)}</TableCell>
                    <TableCell>{formatPrice(ride.quote)}</TableCell>
                    <TableCell>{formatPrice(ride.originalQuote)}</TableCell>
                    <TableCell>{ride.isRoundTrip ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{ride.additionalAssistance || 'N/A'}</TableCell>
                    <TableCell>
                      <Chip 
                        label={ride.status} 
                        color={getStatusColor(ride.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                        <Button 
                          onClick={() => handleUpdateStatus(ride.id, 'pending')} 
                          disabled={ride.status === 'pending'}
                          sx={statusButtonStyle('warning')}
                        >
                          Pending
                        </Button>
                        <Button 
                          onClick={() => handleUpdateStatus(ride.id, 'approved')} 
                          disabled={ride.status === 'approved'}
                          sx={statusButtonStyle('info')}
                        >
                          Approve
                        </Button>
                        <Button 
                          onClick={() => handleUpdateStatus(ride.id, 'completed')} 
                          disabled={ride.status === 'completed'}
                          sx={statusButtonStyle('success')}
                        >
                          Complete
                        </Button>
                        <IconButton 
                          onClick={() => handleDeleteRide(ride.id)} 
                          color="error"
                          size="small"
                          sx={{ mt: 0.5 }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && hasMore && (
        <Button onClick={() => fetchRides(searchTerm, true)} sx={{ mt: 2 }}>
          Load More
        </Button>
      )}
    </Box>
  );
};

export default RideManagement;