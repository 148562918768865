export const logStripeOperation = (operation: string, data: any) => {
    // Remove sensitive information
    const sanitizedData = { ...data };
    if (sanitizedData.token) {
      if (typeof sanitizedData.token === 'string') {
        sanitizedData.token = `${sanitizedData.token.slice(0, 6)}...${sanitizedData.token.slice(-4)}`;
      } else if (sanitizedData.token.id) {
        sanitizedData.token = `${sanitizedData.token.id.slice(0, 6)}...${sanitizedData.token.id.slice(-4)}`;
      } else {
        sanitizedData.token = '[Redacted]';
      }
    }
    if (sanitizedData.card) {
      sanitizedData.card = {
        ...sanitizedData.card,
        number: sanitizedData.card.last4 ? `****${sanitizedData.card.last4}` : '[Redacted]',
      };
      delete sanitizedData.card.cvc;
    }
  
    console.log(`Stripe Operation: ${operation}`, sanitizedData);
  };