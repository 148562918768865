import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import RideForm from '../components/ride/RideForm';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { Navigate } from 'react-router-dom';
import { RideDetails } from '../components/ride/RideForm';

interface UserData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

// Remove the duplicate RideDetails interface

const Dashboard: React.FC = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [price, setPrice] = useState(0);
  const { user } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data() as UserData);
        }
      }
    };

    fetchUserData();
  }, [user]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const handleRideSubmit = async (rideDetails: RideDetails) => {
    try {
      const docRef = await addDoc(collection(db, 'rides'), {
        ...rideDetails,
        userId: user.uid,
        status: 'pending',
        date: new Date().toISOString()
      });
      console.log("Ride booked with ID: ", docRef.id);
      setPrice(rideDetails.quote);
      setShowStatus(true);
    } catch (error) {
      console.error("Error adding ride: ", error);
    }
  };

  console.log("Rendering Dashboard, handleRideSubmit:", handleRideSubmit);

  return (
    <div>
      <h1>Welcome, {userData ? `${userData.firstName} ${userData.lastName}` : user?.email || 'User'}</h1>
      <RideForm onSubmit={handleRideSubmit} />
      {showStatus && <p>Ride booked! Estimated Price: ${price}</p>}
    </div>
  );
};

export default Dashboard;