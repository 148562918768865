// src/components/admin/UserManagement.tsx

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, Tooltip, CircularProgress, Button, Switch } from '@mui/material';
import { collection, query, orderBy, limit, startAfter, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SearchBar from './SearchBar';
import { exportToCSV } from '../../utils/csvExport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';

interface User {
  id: string;
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isAdmin: boolean;
  createdAt?: string;
  lastLogin?: string;
}

const USERS_PER_PAGE = 20;

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const initialFetchDone = useRef(false);

  console.log('Component rendered. State:', { users: users.length, loading, hasMore, searchTerm, error });

  const fetchUsers = useCallback(async (search: string = '', loadMore: boolean = false) => {
    console.log('fetchUsers called:', { search, loadMore, currentLoading: loading });
    setLoading(true);
    setError(null);

    try {
      let userQuery = query(
        collection(db, 'users'),
        orderBy('email'),  // Change this to a field that definitely exists
        limit(USERS_PER_PAGE)
      );

      if (loadMore && lastVisible) {
        userQuery = query(userQuery, startAfter(lastVisible));
      }

      if (search) {
        userQuery = query(userQuery, orderBy('email'), startAfter(search), limit(USERS_PER_PAGE));
      }

      const userSnapshot = await getDocs(userQuery);
      const newUsers = userSnapshot.docs.map(doc => {
        const data = doc.data();
        console.log('User data:', data);  // Log each user's data
        return { id: doc.id, ...data } as User;
      });
      console.log('Fetched users:', newUsers);  // Log all fetched users

      setUsers(prevUsers => {
        const updatedUsers = loadMore ? [...prevUsers, ...newUsers] : newUsers;
        console.log('Updating users:', updatedUsers);  // Log the final user array
        return updatedUsers;
      });
      setLastVisible(userSnapshot.docs[userSnapshot.docs.length - 1]);
      setHasMore(userSnapshot.docs.length === USERS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [lastVisible, loading]);

  useEffect(() => {
    console.log('useEffect triggered, searchTerm:', searchTerm);
    if (!initialFetchDone.current) {
      fetchUsers(searchTerm);
      initialFetchDone.current = true;
    }
  }, [searchTerm, fetchUsers]);

  const handleSearch = (term: string) => {
    console.log('Search term:', term);
    setSearchTerm(term);
    setUsers([]);
    setLastVisible(null);
    setHasMore(true);
    initialFetchDone.current = false;  // Reset this so we can fetch again
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteDoc(doc(db, 'users', userId));
        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Error deleting user:', error);
        setError("Failed to delete user. Please try again.");
      }
    }
  };

  const handleToggleAdminStatus = async (userId: string, currentStatus: boolean) => {
    try {
      await updateDoc(doc(db, 'users', userId), { isAdmin: !currentStatus });
      setUsers(users.map(user => user.id === userId ? { ...user, isAdmin: !currentStatus } : user));
    } catch (error) {
      console.error('Error updating user admin status:', error);
      setError("Failed to update user admin status. Please try again.");
    }
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'MM/dd/yyyy HH:mm:ss');
    } catch (error) {
      console.error(`Error formatting date: ${dateString}`, error);
      return 'Invalid Date';
    }
  };

  const handleExportCSV = () => {
    const dataToExport = users.map(({ id, ...user }) => ({
      ...user,
      createdAt: formatDate(user.createdAt),
      lastLogin: formatDate(user.lastLogin),
    }));
    exportToCSV(dataToExport, 'user_management_data.csv');
  };

  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Typography variant="h6" sx={{ p: 2 }}>User Management</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <SearchBar onSearch={handleSearch} />
        <Tooltip title="Export to CSV">
          <IconButton color="primary" onClick={handleExportCSV} aria-label="export to csv">
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && users.length === 0 && <Typography>No users found.</Typography>}
      {!loading && users.length > 0 && (
        <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email || 'N/A'}</TableCell>
                  <TableCell>{`${user.firstName || ''} ${user.lastName || ''}`.trim() || 'N/A'}</TableCell>
                  <TableCell>{user.phoneNumber || 'N/A'}</TableCell>
                  <TableCell>
                    <Switch
                      checked={user.isAdmin}
                      onChange={() => handleToggleAdminStatus(user.id, user.isAdmin)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{formatDate(user.createdAt)}</TableCell>
                  <TableCell>{formatDate(user.lastLogin)}</TableCell>
                  <TableCell>
                    <Tooltip title="Delete User">
                      <IconButton
                        onClick={() => handleDeleteUser(user.id)}
                        color="error"
                        aria-label="delete user"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && hasMore && (
        <Button onClick={() => fetchUsers(searchTerm, true)} sx={{ mt: 2 }}>
          Load More
        </Button>
      )}
    </Box>
  );
};

export default UserManagement;