import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../context/AuthContext'; // Make sure this path is correct
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsAdmin(userSnap.data().isAdmin === true);
        }
      }
    };

    checkAdminStatus();
  }, [user]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const menuItems = [
    { text: 'Home', path: '/', icon: <HomeIcon />, public: true },
    { text: 'Book a Ride', path: '/book-ride', icon: <DirectionsCarIcon />, public: false },
    { text: 'My Rides', path: '/my-rides', icon: <ListIcon />, public: false },
    { text: 'Settings', path: '/settings', icon: <SettingsIcon />, public: false },
    { text: 'Admin Panel', path: '/admin', icon: <AdminPanelSettingsIcon />, public: false, adminOnly: true },
  ];

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
      role="presentation"
      onClick={toggleNav}
      onKeyDown={toggleNav}
    >
      <List>
        {menuItems.map((item) => (
          ((item.public || user) && (!item.adminOnly || isAdmin)) && (
            <ListItem 
              component={Link} 
              to={item.path} 
              key={item.text}
              sx={{ 
                py: 2,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon sx={{ color: theme.palette.primary.main, minWidth: 56 }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text} 
                primaryTypographyProps={{ 
                  fontSize: '1.2rem',
                  fontWeight: 'medium'
                }}
              />
            </ListItem>
          )
        ))}
        {user ? (
          <ListItem 
            onClick={handleLogout}
            sx={{ 
              py: 2,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.error.main, minWidth: 56 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText 
              primary="Logout" 
              primaryTypographyProps={{ 
                fontSize: '1.2rem',
                fontWeight: 'medium',
                color: theme.palette.error.main
              }}
            />
          </ListItem>
        ) : (
          <ListItem 
            component={Link} 
            to="/login"
            sx={{ 
              py: 2,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.primary.main, minWidth: 56 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText 
              primary="Login" 
              primaryTypographyProps={{ 
                fontSize: '1.2rem',
                fontWeight: 'medium'
              }}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img 
            src="/WaveLogoTransWhite.png" 
            alt="Wave Rideshare Logo" 
            style={{ height: '40px', marginRight: '10px' }} 
          />
          <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Wave Rideshare
          </Typography>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleNav}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {menuItems.map((item) => (
            ((item.public || user) && (!item.adminOnly || isAdmin)) && (
              <Button key={item.text} color="inherit" component={Link} to={item.path}>
                {item.text}
              </Button>
            )
          ))}
          {user ? (
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          ) : (
            <Button color="inherit" component={Link} to="/login">Login</Button>
          )}
        </Box>
      </Toolbar>
      <Drawer
        anchor="right"
        open={isNavOpen}
        onClose={toggleNav}
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: 360,
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </AppBar>
  );
};

export default Header;