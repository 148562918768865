// src/components/admin/Dashboard.tsx

import React, { useEffect, useState } from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';
import { collection, getDocs, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../../firebase';
import Charts from './Charts';
import RecentRides from './RecentRides';
import { RideDetails } from '../../types/RideshareService';

interface DailyPriceData {
  day: string;
  [key: string]: number | string; // This allows for dynamic ride types
}

const Dashboard: React.FC = () => {
  const [userCount, setUserCount] = useState(0);
  const [rideCount, setRideCount] = useState(0);
  const [ridesByStatus, setRidesByStatus] = useState<{ name: string; value: number }[]>([]);
  const [ridesByDay, setRidesByDay] = useState<{ day: string; count: number }[]>([]);
  const [pricesByDay, setPricesByDay] = useState<DailyPriceData[]>([]);
  const [recentRides, setRecentRides] = useState<RideDetails[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const userSnapshot = await getDocs(collection(db, 'users'));
      const rideSnapshot = await getDocs(collection(db, 'rides'));
      setUserCount(userSnapshot.size);
      setRideCount(rideSnapshot.size);

      const statusCount: { [key: string]: number } = {};
      const dayCount: { [key: string]: number } = {};
      const dayPrices: { [key: string]: { [serviceName: string]: number } } = {};
      const serviceNames = new Set<string>();

      const rides = rideSnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
        const data = doc.data() as RideDetails;
        return { ...data, id: doc.id };
      });

      rides.forEach((ride: RideDetails) => {
        statusCount[ride.status] = (statusCount[ride.status] || 0) + 1;
        const day = new Date(ride.date).toLocaleDateString('en-US', { weekday: 'long' });
        dayCount[day] = (dayCount[day] || 0) + 1;
        
        if (!dayPrices[day]) dayPrices[day] = {};
        if (!dayPrices[day][ride.serviceName]) dayPrices[day][ride.serviceName] = 0;
        dayPrices[day][ride.serviceName] += ride.quote;
        
        serviceNames.add(ride.serviceName);
      });

      const newRidesByStatus = Object.entries(statusCount).map(([name, value]) => ({ name, value }));
      const newRidesByDay = Object.entries(dayCount).map(([day, count]) => ({ day, count }));
      
      const newPricesByDay = Object.entries(dayPrices).map(([day, prices]) => {
        const dayData: DailyPriceData = { day };
        serviceNames.forEach(serviceName => {
          dayData[serviceName] = prices[serviceName] || 0;
        });
        return dayData;
      });

      setRidesByStatus(newRidesByStatus);
      setRidesByDay(newRidesByDay);
      setPricesByDay(newPricesByDay);

      const recentRidesData = rides
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 5);
      setRecentRides(recentRidesData);

      console.log('Rides by status:', newRidesByStatus);
      console.log('Rides by day:', newRidesByDay);
      console.log('Prices by day:', newPricesByDay);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h3">{userCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">Total Rides</Typography>
            <Typography variant="h3">{rideCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Charts 
            ridesByStatus={ridesByStatus} 
            ridesByDay={ridesByDay} 
            pricesByDay={pricesByDay}
          />
        </Grid>
        <Grid item xs={12}>
          <RecentRides rides={recentRides} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;