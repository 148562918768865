import React from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethods from './PaymentMethods';
import ProfileSettings from './ProfileSettings';
// import NotificationSettings from './NotificationSettings';
// import PrivacySettings from './PrivacySettings';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const Settings: React.FC = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mb: 8 }}>
      <Typography component="h1" variant="h4" sx={{ my: 4, textAlign: 'center' }}>
        Settings
      </Typography>
      
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              Profile Settings
            </Typography>
            <ProfileSettings />
          </Paper>
        </Grid>

        {/* Commented out Notification Settings
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              Notification Settings
            </Typography>
            <NotificationSettings />
          </Paper>
        </Grid>
        */}

        {/* Commented out Privacy Settings
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              Privacy Settings
            </Typography>
            <PrivacySettings />
          </Paper>
        </Grid>
        */}

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              Payment Methods
            </Typography>
            <Elements stripe={stripePromise}>
              <PaymentMethods />
            </Elements>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;