import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { AuthProvider, useAuth } from './context/AuthContext';
import { GoogleMapsProvider } from './components/maps/GoogleMapsLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './components/Layout'; // Import the Layout component
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Dashboard from './pages/Dashboard';
import Settings from './components/Settings';
import ResetPassword from './components/auth/ResetPassword';
import MyRides from './components/MyRides';
import RideForm from './components/ride/RideForm';
import Admin from './components/admin/Admin';
import AdminLogin from './pages/AdminLogin';
import NotFoundPage from './pages/NotFoundPage';
import { RideDetails } from './components/ride/RideForm';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3498db',
    },
    secondary: {
      main: '#2c3e50',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <GoogleMapsProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
          <Router>
            <AppContent />
            <ToastContainer /> {/* Add this line */}
          </Router>
        </GoogleMapsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

const AppContent: React.FC = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/my-rides" element={<PrivateRoute><MyRides /></PrivateRoute>} />
        <Route path="/book-ride" element={<PrivateRoute><BookRidePage /></PrivateRoute>} />
        <Route path="/admin-login" element={user ? <Navigate to="/admin" replace /> : <AdminLogin />} />
        <Route path="/admin" element={<AdminRoute><Admin /></AdminRoute>} />
        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
      </Routes>
    </Layout>
  );
};

const PrivateRoute: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

const AdminRoute: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !user.isAdmin) {
    return <NotFoundPage />; // Use NotFoundPage instead of redirecting to admin-login
  }

  return <>{children}</>;
};

const BookRidePage: React.FC = () => {
  const navigate = useNavigate();

  const handleRideSubmit = (rideDetails: RideDetails) => {
    try {
      console.log("Ride booked with ID: ")
      
      // Show success message
      toast.success('Ride booked successfully!');
      
      // Redirect to My Rides page
      navigate('/my-rides');
      
      // Optionally, you can add a success message here
      // For example, using a toast notification library or setting a state
    } catch (error) {
      console.error("Error adding ride: ", error);
      toast.error('Failed to book ride. Please try again.');
    }
  };

  return (
    <RideForm onSubmit={handleRideSubmit} />
  );
};

export default App;