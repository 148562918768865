// src/components/PaymentStep.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  Button, Box, Typography, Stepper,
  Step, StepLabel, TextField, useMediaQuery, useTheme,
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { logStripeOperation } from '../../utils/logger';
import { countries, getCountryCodeByName, getCountryNameByCode } from '../../utils/getCountryCodeByName';
import config from '../../config';
import { RideDetails } from '../../types/RideshareService';

interface PaymentStepProps {
  amount: number;
  rideDetails: RideDetails;
  onPaymentComplete: (paymentMethodId: string, rideId: string) => void;
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const PaymentStep: React.FC<PaymentStepProps> = ({ amount, rideDetails, onPaymentComplete }) => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    address: {
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    },
  });
  const [emailError, setEmailError] = useState<string | null>(null);
  const [cardElementKey, setCardElementKey] = useState(0);

  const { user } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const resetForm = useCallback(() => {
    setActiveStep(0);
    setBillingDetails({
      name: '',
      email: '',
      address: {
        line1: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'US',
      },
    });
    setCardElementKey(prev => prev + 1);
    setError(null);
    setSuccess(null);
  }, []);

  const fetchPaymentMethods = useCallback(async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setPaymentMethods(userData.paymentMethods || []);
        setSelectedPaymentMethod(userData.defaultPaymentMethodId || '');
      }
    }
  }, [user]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setBillingDetails(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent as keyof typeof prev] as Record<string, unknown>),
          [child]: value
        }
      }));
    } else {
      setBillingDetails(prev => ({ ...prev, [name]: value }));
    }

    if (name === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError(null);
      }
    }
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const countryName = e.target.value;
    const countryCode = getCountryCodeByName(countryName);
    setBillingDetails(prev => ({
      ...prev,
      address: {
        ...prev.address,
        country: countryCode,
      },
    }));
  };

  const handleNextStep = () => {
    setActiveStep(prev => prev + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(prev => prev - 1);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={billingDetails.name}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={billingDetails.email}
              onChange={handleInputChange}
              error={!!emailError}
              helperText={emailError}
              margin="normal"
            />
          </>
        );
      case 1:
        return (
          <>
            <TextField
              fullWidth
              label="Address"
              name="address.line1"
              value={billingDetails.address.line1}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="City"
              name="address.city"
              value={billingDetails.address.city}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="State"
              name="address.state"
              value={billingDetails.address.state}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Postal Code"
              name="address.postal_code"
              value={billingDetails.address.postal_code}
              onChange={handleInputChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Country</InputLabel>
              <Select
                value={getCountryNameByCode(billingDetails.address.country)}
                onChange={handleCountryChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <CardElement key={cardElementKey} options={CARD_ELEMENT_OPTIONS} />
          </Box>
        );
      default:
        return null;
    }
  };

  const handlePayment = async () => {
    if (!stripe || !elements || !user || !rideDetails) {
      setError('Payment processing is not available at the moment.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      let paymentMethodId = selectedPaymentMethod;

      // Create or retrieve the payment method
      if (selectedPaymentMethod === 'new') {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)!,
          billing_details: billingDetails,
        });

        if (error) {
          throw new Error(error.message);
        }

        paymentMethodId = paymentMethod!.id;

        // Attach payment method to customer
        const customerResponse = await fetch('https://us-central1-accessibleride-7cd9c.cloudfunctions.net/api/payment/create-or-get-customer', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.uid, email: user.email }),
        });

        if (!customerResponse.ok) {
          throw new Error('Failed to create or get customer');
        }

        const { customerId } = await customerResponse.json();

        const attachResponse = await fetch('https://us-central1-accessibleride-7cd9c.cloudfunctions.net/api/payment/attach-payment-method', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ customerId, paymentMethodId }),
        });

        if (!attachResponse.ok) {
          const errorText = await attachResponse.text();
          throw new Error(`Failed to attach payment method: ${errorText}`);
        }

        // Save payment method to Firestore
        await updateDoc(doc(db, 'users', user.uid), {
          paymentMethods: arrayUnion({
            id: paymentMethod!.id,
            brand: paymentMethod!.card!.brand,
            last4: paymentMethod!.card!.last4,
            expMonth: paymentMethod!.card!.exp_month,
            expYear: paymentMethod!.card!.exp_year,
          }),
        });

        // Refresh payment methods
        await fetchPaymentMethods();
      }

      // Create Payment Intent
      const paymentIntentResponse = await fetch('https://us-central1-accessibleride-7cd9c.cloudfunctions.net/api/payment/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: amount,
          currency: 'usd',
          customerId: rideDetails.userId, // Assuming rideDetails contains the customer ID
          paymentMethodId: paymentMethodId,
        }),
      });

      const paymentIntentJson = await paymentIntentResponse.json();

      if (!paymentIntentResponse.ok) {
        const errorText = paymentIntentJson.error || 'Unknown error';
        throw new Error(`Failed to create payment intent: ${errorText}`);
      }

      const { clientSecret } = paymentIntentJson;

      // Confirm the Payment Intent
      const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(clientSecret);

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      // Check payment status
      if (paymentIntent?.status === 'succeeded') {
        // Update the ride document with paymentIntentId and status
        await updateDoc(doc(db, 'rides', rideDetails.id), {
          paymentIntentId: paymentIntent.id,
          status: 'paid',
        });

        setSuccess('Payment successful!');
        onPaymentComplete(paymentMethodId, rideDetails.id);

        // Optionally, send a notification or perform additional actions
      } else {
        throw new Error(`Payment failed with status: ${paymentIntent?.status}`);
      }
    } catch (error) {
      console.error('Payment error:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An error occurred while processing the payment.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment
      </Typography>
      <Typography variant="body1" gutterBottom>
        Total Amount: ${amount.toFixed(2)}
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Payment Method</InputLabel>
        <Select
          value={selectedPaymentMethod}
          onChange={(e) => setSelectedPaymentMethod(e.target.value as string)}
        >
          {paymentMethods.map((method) => (
            <MenuItem key={method.id} value={method.id}>
              {method.brand} **** {method.last4}
            </MenuItem>
          ))}
          <MenuItem value="new">Use a new card</MenuItem>
        </Select>
      </FormControl>
      {selectedPaymentMethod === 'new' && (
        <Box sx={{ mt: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
          <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
            <Step><StepLabel>Billing Info</StepLabel></Step>
            <Step><StepLabel>Address</StepLabel></Step>
            <Step><StepLabel>Card Details</StepLabel></Step>
          </Stepper>
          {renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              onClick={handlePrevStep}
              disabled={activeStep === 0 || loading}
            >
              Back
            </Button>
            {activeStep === 2 ? (
              <Button
                onClick={handlePayment}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Pay'}
              </Button>
            ) : (
              <Button
                onClick={handleNextStep}
                variant="contained"
                color="primary"
                disabled={activeStep === 0 && !!emailError}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      )}
      {selectedPaymentMethod !== 'new' && (
        <Button
          onClick={handlePayment}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Pay'}
        </Button>
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success.main" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
    </Box>
  );
};

export default PaymentStep;
