import React from 'react';
import Signup from '../components/auth/Signup';
import { Container, Typography, Box, Link } from '@mui/material';

const SignupPage: React.FC = () => (
  <Container component="main" maxWidth="xs">
    <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <Signup />
      <Link href="/login" variant="body2">
        Already have an account? Log in
      </Link>
    </Box>
  </Container>
);

export default SignupPage;