import React from 'react';
import { Autocomplete } from '@mui/material';
import { useGoogleMaps } from '../maps/GoogleMapsLoader';

interface AutoCompleteProps {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<{}> | React.ChangeEvent<HTMLInputElement>, value: string | null) => void;
  placeholder: string;
  'aria-describedby': string;
  renderInput: (params: any) => React.ReactNode;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({ id, value, onChange, placeholder, 'aria-describedby': ariaDescribedby, renderInput }) => {
  const { isLoaded, loadError } = useGoogleMaps();
  const [options, setOptions] = React.useState<string[]>([]);
  const autocompleteService = React.useRef<google.maps.places.AutocompleteService | null>(null);

  React.useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newInputValue: string) => {
      if (autocompleteService.current && newInputValue) {
        autocompleteService.current.getPlacePredictions(
          { input: newInputValue },
          (predictions) => {
            if (predictions) {
              const newOptions = predictions.map((prediction) => prediction.description);
              setOptions(newOptions);
            }
          }
        );
      } else {
        setOptions([]);
      }
    },
    []
  );

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Autocomplete
      id={id}
      options={options}
      freeSolo
      value={value}
      onChange={onChange}
      onInputChange={handleInputChange}
      renderInput={(params) =>
        renderInput({
          ...params,
          placeholder,
          'aria-describedby': ariaDescribedby,
        })
      }
    />
  );
};

export default AutoComplete;