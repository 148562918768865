import React, { useState, useEffect, useCallback } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { 
  Button, Box, Typography, CircularProgress, Stepper, 
  Step, StepLabel, TextField, useMediaQuery, useTheme, 
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent 
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { logStripeOperation } from '../utils/logger';
import { countries, getCountryCodeByName, getCountryNameByCode } from '../utils/getCountryCodeByName';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const PaymentMethods: React.FC = () => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    address: {
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US', // Default to US
    },
  });
  const [emailError, setEmailError] = useState<string | null>(null);
  const [cardElementKey, setCardElementKey] = useState(0);

  const { user } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const resetForm = useCallback(() => {
    setActiveStep(0);
    setBillingDetails({
      name: '',
      email: '',
      address: {
        line1: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'US', // Default to US
      },
    });
    setCardElementKey(prev => prev + 1);
    setError(null);
    setSuccess(null);
  }, []);

  useEffect(() => {
    if (!showAddCard) {
      resetForm();
    }
  }, [showAddCard, resetForm]);

  const fetchPaymentMethods = useCallback(async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setPaymentMethods(userData.paymentMethods || []);
        setDefaultPaymentMethodId(userData.defaultPaymentMethodId || null);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (!billingDetails.email || !validateEmail(billingDetails.email)) {
        setEmailError('Please enter a valid email address');
        return;
      } else {
        setEmailError(null);
      }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleBillingDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setBillingDetails(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent as keyof typeof prev] as Record<string, unknown>),
          [child]: value,
        },
      }));
    } else {
      setBillingDetails(prev => ({ ...prev, [name]: value }));
    }

    if (name === 'email') {
      if (!value || !validateEmail(value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError(null);
      }
    }
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const countryName = e.target.value;
    const countryCode = getCountryCodeByName(countryName);
    setBillingDetails(prev => ({
      ...prev,
      address: {
        ...prev.address,
        country: countryCode,
      },
    }));
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Name on Card"
              name="name"
              value={billingDetails.name}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={billingDetails.email}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Address"
              name="address.line1"
              value={billingDetails.address.line1}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="City"
              name="address.city"
              value={billingDetails.address.city}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="State"
              name="address.state"
              value={billingDetails.address.state}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Postal Code"
              name="address.postal_code"
              value={billingDetails.address.postal_code}
              onChange={handleBillingDetailsChange}
              margin="normal"
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Country</InputLabel>
              <Select
                value={getCountryNameByCode(billingDetails.address.country)}
                onChange={handleCountryChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );
      case 2:
        return null; // We'll render CardElement separately
      default:
        return null;
    }
  };

  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements || !user) {
      console.error('Stripe, elements, or user is not available');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error('Card element not found');
      setError('Card element not found');
      setLoading(false);
      return;
    }

    try {
      console.log('Creating PaymentMethod...');
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: billingDetails.name,
          email: billingDetails.email,
          address: {
            line1: billingDetails.address.line1,
            city: billingDetails.address.city,
            state: billingDetails.address.state,
            postal_code: billingDetails.address.postal_code,
            country: billingDetails.address.country,
          },
        },
      });

      console.log('PaymentMethod creation result:', { paymentMethod, error });
      logStripeOperation('createPaymentMethod', { paymentMethod, error });

      if (error) {
        throw new Error(error.message || 'An error occurred');
      }

      if (!paymentMethod) {
        throw new Error('Failed to create PaymentMethod');
      }

      // Get or create Stripe customer
      const customerResponse = await fetch(`${process.env.REACT_APP_API_URL}/payment/create-or-get-customer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid, email: user.email }),
      });

      console.log('Customer response:', customerResponse);

      if (!customerResponse.ok) {
        const errorText = await customerResponse.text();
        console.error('Failed to create or get customer:', errorText);
        throw new Error(`Failed to create or get customer: ${errorText}`);
      }

      const { customerId } = await customerResponse.json();
      console.log('Retrieved customerId:', customerId);

      // Attach payment method to customer
      const attachResponse = await fetch(`${process.env.REACT_APP_API_URL}/payment/attach-payment-method`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ customerId, paymentMethodId: paymentMethod.id }),
      });      

      console.log('Attach payment method response:', attachResponse);

      if (!attachResponse.ok) {
        const errorText = await attachResponse.text();
        console.error('Failed to attach payment method:', errorText);
        throw new Error(`Failed to attach payment method: ${errorText}`);
      }

      const attachResult = await attachResponse.json();
      console.log('Attach payment method result:', attachResult);

      // Clear the CardElement immediately after successful PaymentMethod creation
      cardElement.clear();

      // Add payment method to Firestore
      await updateDoc(doc(db, 'users', user.uid), {
        stripeCustomerId: customerId,
        paymentMethods: arrayUnion({
          id: paymentMethod.id,
          last4: paymentMethod.card?.last4,
          brand: paymentMethod.card?.brand,
          expMonth: paymentMethod.card?.exp_month,
          expYear: paymentMethod.card?.exp_year,
          name: billingDetails.name,
          address: billingDetails.address,
        })
      });

      logStripeOperation('addPaymentMethod', { 
        userId: user.uid, 
        customerId,
        last4: paymentMethod.card?.last4, 
        brand: paymentMethod.card?.brand,
        expMonth: paymentMethod.card?.exp_month,
        expYear: paymentMethod.card?.exp_year,
      });

      // Fetch updated payment methods
      await fetchPaymentMethods();

      // Reset the form
      resetForm();

      // Show success message
      setSuccess('Payment method added successfully');
    } catch (error) {
      console.error('Error in handleAddPaymentMethod:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleSetDefaultPaymentMethod = async (methodId: string) => {
    if (!user) return;
    setLoading(true);
    try {
      await updateDoc(doc(db, 'users', user.uid), {
        defaultPaymentMethodId: methodId
      });
      setDefaultPaymentMethodId(methodId);
      setSuccess('Default payment method updated');
    } catch (err) {
      setError('Failed to update default payment method');
      console.error('Error setting default payment method:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePaymentMethod = async (methodId: string) => {
    if (!user) return;
    setLoading(true);
    try {
      const methodToRemove = paymentMethods.find(method => method.id === methodId);
      await updateDoc(doc(db, 'users', user.uid), {
        paymentMethods: arrayRemove(methodToRemove)
      });
      if (defaultPaymentMethodId === methodId) {
        await updateDoc(doc(db, 'users', user.uid), {
          defaultPaymentMethodId: null
        });
        setDefaultPaymentMethodId(null);
      }
      await fetchPaymentMethods();
      setSuccess('Payment method removed successfully');
    } catch (err) {
      setError('Failed to remove payment method');
      console.error('Error removing payment method:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', maxWidth: 600, mt: 3 }}>
        {paymentMethods.map(method => (
          <Box key={method.id} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <CreditCardIcon sx={{ mr: 2 }} />
            <Typography sx={{ flexGrow: 1 }}>{`${method.brand} **** **** **** ${method.last4}`}</Typography>
            {method.id === defaultPaymentMethodId ? (
              <StarIcon color="primary" sx={{ mr: 1 }} />
            ) : (
              <Button 
                onClick={() => handleSetDefaultPaymentMethod(method.id)} 
                disabled={loading}
                startIcon={<StarBorderIcon />}
                sx={{ mr: 1 }}
              >
                Set Default
              </Button>
            )}
            <Button onClick={() => handleRemovePaymentMethod(method.id)} color="error" disabled={loading}>
              Remove
            </Button>
          </Box>
        ))}
        {!showAddCard ? (
          <Button
            onClick={() => setShowAddCard(true)}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            startIcon={<AddIcon />}
          >
            Add New Card
          </Button>
        ) : (
          <Box sx={{ mt: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
              <Step><StepLabel>Billing Info</StepLabel></Step>
              <Step><StepLabel>Address</StepLabel></Step>
              <Step><StepLabel>Card Details</StepLabel></Step>
            </Stepper>
            {renderStepContent(activeStep)}
            {activeStep === 2 && (
              <Box sx={{ mt: 2 }}>
                <CardElement key={cardElementKey} options={CARD_ELEMENT_OPTIONS} />
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                onClick={() => activeStep === 0 ? setShowAddCard(false) : handlePrevStep()}
                disabled={loading}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              {activeStep === 2 ? (
                <Button
                  onClick={handleAddPaymentMethod}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={24} /> : <LockIcon />}
                >
                  {loading ? 'Processing...' : 'Add Payment Method'}
                </Button>
              ) : (
                <Button
                  onClick={handleNextStep}
                  variant="contained"
                  color="primary"
                  disabled={activeStep === 0 && !!emailError}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        )}
        {success && (
          <Typography color="success.main" sx={{ mt: 2, textAlign: 'center' }}>
            {success}
          </Typography>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
            {error}
          </Typography>
        )}
        <Typography variant="body2" sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LockIcon sx={{ mr: 1, fontSize: 'small' }} />
          Secure payment powered by Stripe
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentMethods;