import React, { createContext, useContext, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

interface GoogleMapsContextType {
  isLoaded: boolean;
  loadError: Error | null;
}

const GoogleMapsContext = createContext<GoogleMapsContextType>({ isLoaded: false, loadError: null });

export const useGoogleMaps = () => useContext(GoogleMapsContext);

interface GoogleMapsProviderProps {
  children: React.ReactNode;
  apiKey: string;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({ children, apiKey }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState<Error | null>(null);

  useEffect(() => {
    // console.log("Attempting to load Google Maps API with key:", apiKey);
    const loader = new Loader({
      apiKey,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load()
      .then(() => {
        console.log("Google Maps API loaded successfully");
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
        setLoadError(error);
      });
  }, [apiKey]);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
