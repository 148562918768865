export interface Country {
    name: string;
    code: string;
  }
  
  export const countries: Country[] = [
    { name: "United States", code: "US" },
    { name: "Canada", code: "CA" },
    { name: "United Kingdom", code: "GB" },
    // Add more countries as needed
  ];
  
  export const getCountryCodeByName = (name: string): string => {
    const country = countries.find(c => c.name.toLowerCase() === name.toLowerCase());
    return country ? country.code : "";
  };
  
  export const getCountryNameByCode = (code: string): string => {
    const country = countries.find(c => c.code.toLowerCase() === code.toLowerCase());
    return country ? country.name : "";
  };