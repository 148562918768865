import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { RideDetails } from '../../types/RideshareService';

interface RecentRidesProps {
  rides: RideDetails[];
}

const RecentRides: React.FC<RecentRidesProps> = ({ rides }): React.ReactElement => {
  return (
    <Paper>
      <Typography variant="h6" gutterBottom component="div">
        Recent Rides
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Pickup</TableCell>
              <TableCell>Dropoff</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rides.map((ride) => (
              <TableRow key={ride.id}>
                <TableCell>{new Date(ride.date).toLocaleString()}</TableCell>
                <TableCell>{ride.status}</TableCell>
                <TableCell>{ride.pickup}</TableCell>
                <TableCell>{ride.dropoff}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RecentRides;