import React from 'react';
import { Typography, Container, Box, Button, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import AccessibleIcon from '@mui/icons-material/Accessible';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SecurityIcon from '@mui/icons-material/Security';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const Home: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const features = [
    {
      icon: <AccessibleIcon fontSize="large" />,
      title: "Accessible for All",
      description: "Our service caters to passengers with various accessibility needs, including visual impairments."
    },
    {
      icon: <ElderlyIcon fontSize="large" />,
      title: "Senior-Friendly Rides",
      description: "Specialized service for seniors, offering extra assistance and patience."
    },
    {
      icon: <ChildCareIcon fontSize="large" />,
      title: "Child-Safe Transportation",
      description: "Equipped with proper car seats and trained drivers for transporting children safely."
    },
    {
      icon: <LocalHospitalIcon fontSize="large" />,
      title: "Medical Transport",
      description: "Non-emergency medical transportation with drivers trained in basic medical assistance."
    },
    {
      icon: <DirectionsCarIcon fontSize="large" />,
      title: "Reliable Transportation",
      description: "Trained drivers ensure safe and comfortable rides to your destination."
    },
    {
      icon: <SecurityIcon fontSize="large" />,
      title: "Secure and Trustworthy",
      description: "We prioritize your safety with thorough background checks and vehicle inspections."
    }
  ];

  return (
    <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography 
        variant={isMobile ? "h3" : "h2"} 
        component="h1" 
        gutterBottom 
        align="center" 
        sx={{ 
          fontWeight: 'bold', 
          color: '#00b6d9',
          wordBreak: 'break-word',
          hyphens: 'auto',
          mb: 3,
        }}
      >
        Welcome to Wave Rideshare
      </Typography>

      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            component="img"
            src="/WaveRSLogoMed.png"
            alt="Wave Rideshare Logo"
            sx={{
              width: isMobile ? '200px' : '250px',
              height: 'auto',
              mb: 3,
            }}
          />

          {!user && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2, flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
              <Button fullWidth={isMobile} variant="contained" color="primary" component={RouterLink} to="/signup" size="large" sx={{ boxShadow: 3 }}>
                Sign Up
              </Button>
              <Button fullWidth={isMobile} variant="outlined" color="primary" component={RouterLink} to="/login" size="large" sx={{ boxShadow: 3 }}>
                Log In
              </Button>
            </Box>
          )}

          {user && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button fullWidth variant="contained" color="primary" component={RouterLink} to="/book-ride" size="large" sx={{ boxShadow: 3 }}>
                Book a Ride
              </Button>
            </Box>
          )}
        </Paper>
      </Container>

      <Box sx={{ width: '100%', backgroundColor: '#f5f5f5', py: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ color: '#00b6d9', mb: 3 }}>
            Our Services
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={2} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  {feature.icon}
                  <Typography variant="h6" component="h3" sx={{ my: 2, color: '#00b6d9' }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1">
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ color: '#00b6d9', mb: 3 }}>
            About Wave Rideshare
          </Typography>
          <Typography variant="body1" paragraph>
            Wave Rideshare is committed to providing accessible transportation for everyone. Our mission is to ensure that all individuals, regardless of their abilities, have access to safe, reliable, and comfortable transportation.
          </Typography>
          <Typography variant="body1">
            Whether you're visually impaired, a senior citizen, or someone who needs extra assistance, Wave Rideshare is here to serve you. Our trained drivers and specially equipped vehicles are ready to meet your unique transportation needs.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default Home;