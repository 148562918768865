// src/components/Footer.tsx

import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Wave Rideshare provides accessible transportation for everyone.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              333 West Trade St, Charlotte, NC 28202
            </Typography>
            <Link href="mailto:support@waveride.co" color="inherit" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <EmailIcon sx={{ mr: 1 }} fontSize="small" />
              support@waveride.co
            </Link>
            <Link href="tel:980-880-5149" color="inherit" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <PhoneIcon sx={{ mr: 1 }} fontSize="small" />
              980-880-5149
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <IconButton href="https://www.facebook.com/waverideshare" target="_blank" rel="noopener noreferrer" color="inherit">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com/waverideshare" target="_blank" rel="noopener noreferrer" color="inherit">
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/company/wave-mobile-technologies/" target="_blank" rel="noopener noreferrer" color="inherit">
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link 
              color="inherit" 
              component={RouterLink} 
              to="/"
            >
              Wave Rideshare
            </Link>{' '}
            {new Date().getFullYear()}
            {' | '}
            <Link
              color="inherit"
              href="https://waveride.co/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            {' | '}
            <Link
              color="inherit"
              href="https://waveride.co/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;